import React from "react";
import { DocumentCard, DocumentCardActivity, DocumentCardPreview, DocumentCardActions, DocumentCardTitle, DocumentCardType, DocumentCardLocation, IDocumentCardPreviewProps } from "@fluentui/react/lib/DocumentCard";
import { ImageFit } from "@fluentui/react/lib/Image";
import bookImage from "../assets/book.png";

const Card = ({ title, identifier, onClick }) => {
  const documentCardActions = [
    {
      iconProps: { iconName: "Share" },

      ariaLabel: "share action",
    },
  ];
  const previewProps = {
    previewImages: [
      {
        name: "Revenue stream proposal fiscal year 2016 version02.pptx",
        linkProps: {
          href: "http://bing.com",
          target: "_blank",
        },
        previewImageSrc: bookImage,
        iconSrc: null,
        imageFit: ImageFit.cover,

        height: 196,
      },
    ],
  };
  return (
    <DocumentCard type={DocumentCardType.normal} onClick={onClick}>
      <DocumentCardPreview {...previewProps} />
      <DocumentCardTitle styles={{ root: { minHeight: 80 } }} title={title} shouldTruncate />
      <DocumentCardActions actions={documentCardActions} views={432} />
      {/* <DocumentCardLocation
      location="Heft gestalten"/> */}
    </DocumentCard>
  );
};

export default Card;
