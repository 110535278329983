import React, { useRef, useEffect } from "react";
import raw_data from "./assets/data.json";
import Card from "./components/Card";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { Label, MessageBar, Text, TextField } from "@fluentui/react";

const App = () => {
  const [payload, setPayload] = React.useState(raw_data);
  const tableRef = useRef(null);
  React.useEffect(() => {
    // fetch("http://localhost:3000/database.php/?q=limit 1000").then((e) => {
    //   e.json().then((result) => {
    //     console.log(result);
    //     setPayload([...result]);
    //   });
    // });
  }, []);

  const [selectedHeftsorte, setSelectedHeftsorte] = React.useState(null);
  const [selectedTyp, setSelectedTyp] = React.useState("");
  const [selectedFormat, setSelectedFormat] = React.useState(null);
  const [selectedSeitenanzahl, setSelectedSeitenanzahl] = React.useState(null);
  const [selectedBoxType, setSelectedBoxType] = React.useState("");
  const [selectedColor, setSelectedColor] = React.useState("");
  const [selectedRecType, setSelectedRecType] = React.useState("");
  const [cartItems, setCartItems] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const SUBSCRIPTION_KEY = "5EH8L56ewxDUzqcQoConrA";

  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showConfirm, setConfirm] = React.useState(false);

  const heftsorten = [...new Set(payload.map((e) => e.Heftsorten))];
  const [types, setTypes] = React.useState([]);
  const [formate, setFormate] = React.useState([]);
  const [seiten, setSeiten] = React.useState([]);
  const [preise, setPreise] = React.useState([]);

  const colors = [
    "Gelb",
    "Goldgelb",
    "Orange",
    "Rot",
    "Rosa",
    "Violett",
    "Blau",
    "Hellblau",
    "Hellgrün",
    "Grün",
  ]; // Registerkarton
  const recyclingkarton_types = ["Wurzelfein", "Aladin", "Salome", "Elfenblau"]; // Recyclingkarton

  //Recyclingkarton 300 g/m2 marmoriert bedruckt nur in A4 hoch und A4 quer

  const boxTypes = ["Registerkarton", "Recyclingkarton"];

  const [form, setForm] = React.useState({
    firstName: "",
    lastName: "",
    address: "",
    zip: "",
    email: "",
    phone: "",
    state: "Österreich",
    place: "",
    notes: "",
    confirmed: false,
    htmlTable: "",
  });

  const addToCart = () => {
    setIsOpen(false);
    const items = payload.filter(
      (e) =>
        e.Heftsorten == selectedHeftsorte &&
        e.Typ == selectedTyp &&
        e.Format == selectedFormat &&
        e.Seitenanzahl == selectedSeitenanzahl
    );
    // const _heftsorten = selectedBoxType != null && selectedColor != null ? items[0].Heftsorten + " " + selectedBoxType + " " + selectedColor + " " + selectedRecType : items[0].Heftsorten;
    const _heftsorten = items[0].Heftsorten;

    if (items.length > 1) {
      console.log("Mehr als ein Item gefunden!!!!!!!!!!!!");
    }
    setCartItems([
      ...cartItems,
      {
        ...items[0],
        count: 1,
        price: +items[0].Preis,
        Heftsorten: _heftsorten,
        selectedBoxType,
        selectedColor,
        selectedRecType,
      },
    ]);
    setTypes([]);
    setFormate([]);
    setSeiten([]);
    setPreise([]);
    setSelectedTyp(null);
    setSelectedFormat(null);
    setSelectedSeitenanzahl(null);
    setSelectedBoxType("");
    setSelectedColor("");
    setSelectedRecType("");
  };

  const showAddCartButton =
    selectedHeftsorte && selectedTyp && selectedFormat && selectedSeitenanzahl;

  const removeFromCart = (index) => {
    let _newItems = cartItems;
    _newItems.splice(index, 1);
    setCartItems([..._newItems]);
  };

  const updateCount = (evt, index) => {
    let _items = cartItems;
    _items[index].count = evt.target.value;
    _items[index].Preis = +_items[index].price * _items[index].count;
    setCartItems([..._items]);
  };

  const getBooktypeIdentifier = (type) => {
    if (type == "Notenhefte") return "x2eamx6w287v85rg4g35zron9";
    if (type == "Hefte mit Hilfslinien für Formenzeichnen")
      return "z5zg9rea5n64zmgxv73oxw28m";
    if (type == "Schreibhefte mit Richtlinien")
      return "z3569ew7mrg439mm4an3zx82o";
    if (type == "Schreibhefte") return "o8o67mx5zg242w9gv9n8rewa3";
    if (type == "Rechenhefte") return "5x7am8e52g9vnn3nvxo3w6nrz";
    if (type == "Epochenhefte") return "8xa9x3wnmz6vanwr4587eog2r";
    return "953gmewx6o245zg8vzran5897";
  };

  const handleSubmit = () => {
    if (!form.confirmed) {
      setConfirm(true);
      return;
    }
    setLoading(true);
    console.log(cartItems);
    // console.log(selectedBoxType + " " + selectedColor + " " + selectedRecType);
    const order_id = broofa();
    const _items = cartItems;
    const _body = {
      form_id: 2,
      20: cartItems.reduce((a, b) => a + (+b.Preis || 0), 0).toFixed(2),
      18: order_id,
      12.3: form.firstName,
      12.6: form.lastName,
      13: form.phone,
      15: form.email,
      14.1: form.address,
      14.2: form.zip,
      14.3: form.state,
      14.4: form.place,
      17: form.notes,
      htmlTable: form.htmlTable,
      21: _items.map((item) => ({
        Anzahl: item.count,
        Umschlag:
          item.selectedBoxType +
          " " +
          item.selectedColor +
          " " +
          item.selectedRecType,
        Heftsorte: item.Heftsorten,
        Typ: item.Typ,
        Format: item.Format,
        Seiten: item.Seitenanzahl,
        Preis: item.price,
      })),
    };

    // console.log(_body);
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          window.btoa(
            "ck_b866242ebdec00fee360dbf47b638f18e1b9eca2" +
              ":" +
              "cs_f0c3fb776d23666729099efe614e498dfe7d0a08"
          ),
      },
      body: JSON.stringify(_body),
    };
    fetch("https://camphill.at/wp-json/gf/v2/entries/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const entryId = data.id;
        const entryUrl = `https://camphill.at/wp-admin/admin.php?page=gf_entries&view=entry&id=2&lid=${entryId}`;

        fetch("https://hooks.zapier.com/hooks/catch/14137375/3tue129/", {
          method: "POST",

          body: JSON.stringify({
            url: entryUrl,
            body: _body,
          }),
        })
          .then(console.log)
          .catch(console.log);

        setCartItems([]);
        setLoading(false);
        setShowModal(true);
      });

    // submitToDimetrics(order_id);
  };

  const onClickHeftsorte = (term) => {
    setIsOpen(true);
    const _types = payload
      .filter((e) => e.Heftsorten == term)
      .map((e) => e.Typ);
    setTypes([...new Set(_types)]);
    setSelectedHeftsorte(term);
  };

  const onClickTyp = (term) => {
    const _format = payload
      .filter((e) => e.Heftsorten == selectedHeftsorte && e.Typ == term)
      .map((e) => e.Format);
    setFormate([...new Set(_format)]);
    setSelectedTyp(term);
  };

  const onClickFormat = (term) => {
    const _seiten = payload
      .filter(
        (e) =>
          e.Heftsorten == selectedHeftsorte &&
          e.Typ == selectedTyp &&
          e.Format == term
      )
      .map((e) => e.Seitenanzahl);
    setSeiten([...new Set(_seiten)]);
    setSelectedFormat(term);
  };

  const onClickSeiten = (term) => {
    const _preise = payload
      .filter(
        (e) =>
          e.Heftsorten == selectedHeftsorte &&
          e.Typ == selectedTyp &&
          e.Format == selectedFormat &&
          e.Seitenanzahl == term
      )
      .map((e) => e.Preis);
    setPreise([..._preise]);
    setSelectedSeitenanzahl(term);
  };

  const onRenderFooterContent = () => (
    <div>
      <PrimaryButton
        disabled={!showAddCartButton}
        onClick={addToCart}
        styles={buttonStyles}
      >
        In den Warenkorb
      </PrimaryButton>
      <DefaultButton onClick={() => setIsOpen(false)}>Abbrechen</DefaultButton>
    </div>
  );

  const validateForm = () => {
    if (
      form.address != "" &&
      form.email != "" &&
      form.firstName != "" &&
      form.lastName != "" &&
      form.phone != "" &&
      form.phone != "" &&
      form.place != "" &&
      form.state != "" &&
      form.zip != ""
    ) {
      setForm((e) => (e = { ...e, confirmed: true }));
      setConfirm(false);
      handleSubmit();
    } else {
      setForm((e) => (e = { ...e, confirmed: false }));
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      const htmlContent = tableRef.current.outerHTML;
      console.log(htmlContent); // logs: "Hello World"

      // You can now store this HTML in a JSON object
      const myJsonObject = { content: htmlContent };
      console.log(myJsonObject); // logs: {content: "Hello World"}
      setForm((e) => (e = { ...e, htmlTable: htmlContent }));
    }
  }, [cartItems]);

  return (
    <div className="row">
      <div className="col-12 d-flex flex-wrap">
        {heftsorten.map((e, i) => {
          return (
            <div className="m-2">
              <Card onClick={() => onClickHeftsorte(e)} key={i} title={e} />
            </div>
          );
        })}
      </div>

      <div className="col-12">
        {/* {showAddCartButton && (
          <button onClick={addToCart} type="button" className="btn btn-primary">
            In den Warenkorb
          </button>
        )} */}
        <table id={"table"} ref={tableRef} className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Heftsorte</th>
              <th scope="col">Typ</th>
              <th scope="col">Format</th>
              <th scope="col">Seiten</th>
              <th scope="col">Anzahl</th>
              <th scope="col">Preis</th>
              <th scope="col">Option</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item, i) => {
              return (
                <tr key={i + 1}>
                  <th scope="row">{i + 1}</th>
                  <td>
                    {item.Heftsorten +
                      " " +
                      item.selectedBoxType +
                      " " +
                      item.selectedColor +
                      " " +
                      item.selectedRecType}
                  </td>
                  <td>{item.Typ}</td>
                  <td>{item.Format}</td>
                  <td>{item.Seitenanzahl}</td>
                  <td>
                    <input
                      onChange={(evt) => updateCount(evt, i)}
                      value={item.count}
                      type="number"
                      name="tentacles"
                      min="1"
                      max="100"
                    />
                  </td>
                  <td>{(+item.Preis).toFixed(2)}</td>
                  <td>
                    <button
                      onClick={() => removeFromCart(i)}
                      type="button"
                      className="btn btn-secondary btn-sm"
                    >
                      Entfernen
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            <tr>
              <td>Summe</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{cartItems.reduce((a, b) => a + (+b.count || 0), 0)}</td>
              <td>
                {cartItems.reduce((a, b) => a + (+b.Preis || 0), 0).toFixed(2)}
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="col">
        <button
          disabled={loading || cartItems.length <= 0}
          onClick={() => handleSubmit()}
          type="button"
          className="btn btn-secondary btn-sm"
        >
          Bestellung absenden
        </button>
      </div>

      {/* Modal view */}
      <div>
        {showModal && (
          <div style={{ display: "block" }} className="modal" tabindex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Ihre Bestellung wurde aufgegeben
                  </h5>
                  <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p>
                    Vielen Dank! Ihre Bestellung wird zum nächstmöglichen
                    Zeitpunkt bearbeitet.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Schließen
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Modal view */}
      <div>
        {showConfirm && (
          <div style={{ display: "block" }} className="modal" tabindex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Bitte überprüfen Sie Ihre Daten
                  </h5>
                  <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <Text>
                        Bitte vervollständigen Sie Ihre Kontaktdaten um mit der
                        Bestellung fortfahren zu können.
                      </Text>
                    </div>
                    <TextField
                      required
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, firstName: value }))
                      }
                      className="col-6"
                      label="Vorname"
                      value={form.firstName}
                    />
                    <TextField
                      required
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, lastName: value }))
                      }
                      className="col-6"
                      label="Nachname"
                      value={form.lastName}
                    />
                    <TextField
                      required
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, email: value }))
                      }
                      className="col-6"
                      label="Email"
                      value={form.email}
                    />
                    <TextField
                      required
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, phone: value }))
                      }
                      className="col-6"
                      label="Telefon"
                      value={form.phone}
                    />
                    <TextField
                      required
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, address: value }))
                      }
                      className="col-12"
                      label="Adresse"
                      value={form.address}
                    />
                    <TextField
                      required
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, zip: value }))
                      }
                      className="col-4"
                      label="Postleitzahl"
                      value={form.zip}
                    />
                    <TextField
                      required
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, place: value }))
                      }
                      className="col-8"
                      label="Stadt"
                      value={form.place}
                    />
                    <TextField
                      styles={{ root: { display: "none" } }}
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, state: value }))
                      }
                      className="col-6"
                      label="Land"
                      value={form.state}
                    />
                    <TextField
                      required
                      onChange={(ev, value) =>
                        setForm((e) => (e = { ...e, notes: value }))
                      }
                      className="col-8"
                      label="Sonstige Angaben"
                      value={form.notes}
                    />
                    <div className="col-12 mt-2">
                      {!form.confirmed && (
                        <MessageBar>
                          Es müssen alle Felder ausgefüllt werden
                        </MessageBar>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => validateForm()}
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    {!form.confirmed ? (
                      <>Daten übernehmen</>
                    ) : (
                      <>Bestellung abschließen</>
                    )}
                  </button>
                  <button
                    onClick={() => setConfirm(false)}
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Schließen
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Panel
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        headerText="Heft zusammenstellen"
        closeButtonAriaLabel="Schließen"
        onRenderFooterContent={onRenderFooterContent}
        // Stretch panel content to fill the available height so the footer is positioned
        // at the bottom of the page
        customWidth={"500px"}
        type={PanelType.custom}
        isFooterAtBottom={true}
      >
        <div className="row">
          <div className="col-12">
            <Text>
              Individualisieren Sie hier ihre ausgewählte Heftsorte:{" "}
              <b>{selectedHeftsorte}</b>
            </Text>
          </div>
          <div className="col-12 mt-3">
            <Label>Papiersorte</Label>
            <select
              className="form-select"
              size="7"
              aria-label="size 10 select example"
            >
              {types.map((e, i) => {
                return (
                  <option onClick={() => onClickTyp(e)} key={i} value={e}>
                    {e}
                  </option>
                );
              })}
            </select>
          </div>
          {/* If "Rechenhefte" selected */}
          {selectedHeftsorte == "Rechenhefte" && (
            <div className="col-12 mt-3">
              <Label>Kartonage</Label>
              {boxTypes.map((e, i) => {
                return (
                  <div key={i} className="form-check form-check-inline">
                    <input
                      onClick={() => setSelectedBoxType(e)}
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptionsBox"
                      id={i + "box"}
                      value={e}
                    />
                    <label className="form-check-label" htmlFor={i + "box"}>
                      {e}
                    </label>
                  </div>
                );
              })}
            </div>
          )}

          {selectedBoxType == "Recyclingkarton" && (
            <div className="col-12 mt-3">
              <Label>Art</Label>
              <select
                className="form-select"
                size="7"
                aria-label="size 10 select example"
              >
                {recyclingkarton_types.map((e, i) => {
                  return (
                    <option
                      onClick={() => setSelectedRecType(e)}
                      key={i}
                      value={e}
                    >
                      {e}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {selectedBoxType == "Registerkarton" && (
            <div className="col-12 mt-3">
              <Label>Farbe</Label>
              <select
                className="form-select"
                size="7"
                aria-label="size 10 select example"
              >
                {colors.map((e, i) => {
                  return (
                    <option
                      onClick={() => setSelectedColor(e)}
                      key={i}
                      value={e}
                    >
                      {e}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          <div className="col-12 mt-3">
            {formate.length > 0 && <Label>Format</Label>}
            {formate.map((e, i) => {
              return (
                <div key={i} className="form-check form-check-inline">
                  <input
                    onClick={() => onClickFormat(e)}
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptionsFormat"
                    id={i + "format"}
                    value={e}
                  />
                  <label className="form-check-label" htmlFor={i + "format"}>
                    {e}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="col-12 mt-3">
            {seiten.length > 0 && <Label>Seitenanzahl</Label>}
            {seiten.map((e, i) => {
              return (
                <div key={i} className="form-check form-check-inline">
                  <input
                    onClick={() => onClickSeiten(e)}
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptionsSeitgen"
                    id={i + seiten}
                    value={e}
                  />
                  <label className="form-check-label" htmlFor={i + "seiten"}>
                    {e}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default App;

function broofa() {
  return "xxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const buttonStyles = { root: { marginRight: 8 } };
